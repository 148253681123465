import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import List from '../components/List'
import LayoutListView from '../components/LayoutListView'

const resources = ({ location }) => {
  const configID = '5XEGFeMHiMpMaN6q0uI44U'
  const queryData = useStaticQuery(graphql`
    {
      allContentfulSubMenu(
        filter: { menu: { eq: "OpenResources" } }
        sort: { fields: title, order: ASC }
      ) {
        edges {
          node {
            id
            title
            url
            image {
              file {
                url
              }
            }
          }
        }
      }
    }
  `)

  const TYPE = 'Resources'
  const IMAGE_HEIGHT = 150
  const data = queryData.allContentfulSubMenu.edges.map(obj => ({
    id: obj.node.id,
    title: obj.node.title,
    body: null,
    image: obj.node.image,
    imageHeight: IMAGE_HEIGHT,
    slug: obj.node.url,
    category: null,
    type: TYPE
  }))

  return (
    <LayoutListView path={location.pathname} configID={configID}>
      <List data={data} itemType="subMenu" className="items-container" />
    </LayoutListView>
  )
}

export default resources

resources.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string
  }).isRequired
}
